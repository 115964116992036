import React, {Component} from 'react';
import PredictionIcon from "./PredictionIcon";
import {t} from "../../../translation/translator";
import {ReferentialCategoryType} from "../../../type/ReferentialType";
import {CustomSelect} from "../CustomSelect";
import TunnelReducerType, {Member} from "../../../type/TunnelReducerType";
import ReducerType from "../../../type/ReducerType";
import {connect} from "react-redux";
import ActionLiveType from "../../../reducer/live/ActionLiveType";
import ActionTunnel from "../../../reducer/tunnel/ActionTunnel";
import {useCookies} from "react-cookie";
import CategoryFilter from "../CategoryFilter";
import {Toggle} from "../../Toggle";
import {SessionTypeEnum} from "../../../../../../../../../server/common/Enum/live/SessionTypeEnum";
import {inIframe} from "../../../reducer/live/reducer";

const storeToProps = (state: ReducerType) => {
    return {
        replay: state.live.live.params.replay,
        championship: state.live.championship.id,
        member: state.tunnel.member,
        noLogin: state.live.noLogin,
        lang: state.live.lang,
        classifications: state.live.referential.classifications,
        expert: state.live.expert,
        bestSectorMode: state.live.bestSectorMode,
        currentCategory: state.live.currentCategory,
        listCategories: state.live.referential.categories,
        currentSession: state.live.currentSession
    }
};
const storeDispatchToProps = (dispatch) => ({
    togglePredictive: (enable: boolean) => dispatch(ActionLiveType.togglePredictive(enable)),
    toggleExpert: (enable: boolean) => dispatch(ActionLiveType.toggleExpert(enable)),
    toggleBestSectorMode: (enable: boolean) => dispatch(ActionLiveType.toggleBestSectorMode(enable)),
    filterCategory: (category: number) => dispatch(ActionLiveType.filterCategory(category)),
    showTunnel: () => dispatch(ActionTunnel.toggleTunnel(false)),
    showModalRequestLogin: () => dispatch(ActionLiveType.updateShowModalRequestLogin(true))
});

const LiveTableFilterView = (props: ReturnType<typeof storeToProps> & ReturnType<typeof storeDispatchToProps>) => {
    const [cookies, setCookie] = useCookies(['expert', 'bestsectormode']);
    function toggleExpert(e) {
        if (null == props.member && false === props.noLogin) {
            //alert(t('login_to_access', props.lang));
            props.showModalRequestLogin();
            props.showTunnel();
            e.preventDefault();
        } else {
            // save cookie
            setCookie('expert', !props.expert ? "1" : "0");
            props.toggleExpert(!props.expert);
        }
    }
    function toggleBestSectorMode(e) {
        if (null == props.member && false === props.noLogin) {
            //alert(t('login_to_access', props.lang));
            props.showModalRequestLogin();
            props.showTunnel();
            e.preventDefault();
        } else {
            // save cookie
            setCookie('bestsectormode', !props.bestSectorMode ? "1" : "0");
            props.toggleBestSectorMode(!props.bestSectorMode);
        }
    }

    function togglePredictive(e) {
        if (null == props.member && false === props.noLogin) {
            //alert(t('login_to_access', props.lang));
            props.showModalRequestLogin();
            props.showTunnel();
            e.preventDefault();
        } else {
            props.togglePredictive(true);
        }
    }

    return (
        <div>
            <div className="table-header">
                <CategoryFilter enabledAllByCategory />
                {!inIframe() && !props.replay &&
                    <>
                        <div className="expert-mode">
                            {props.expert &&
                            <Toggle checked={props.bestSectorMode} label="best_sectors" onChange={toggleBestSectorMode}
                                    lang={props.lang}/>
                            }

                            <Toggle checked={props.expert} label="expert_mode" onChange={toggleExpert} lang={props.lang} />
                        </div>

                        {props.currentSession && props.currentSession.type_id == SessionTypeEnum.RACE &&
                            <a className="prediction" onClick={togglePredictive}>
                                <div/>
                                <span className="label">{t("predictif", props.lang)}</span>
                                <PredictionIcon/>
                            </a>
                        }
                    </>
                }
            </div>
            <div className="table-header-select ">
                <div className="actions">
                    <div className="select-secondary">

                        <CustomSelect items={props.listCategories.map((cat, index) => ({
                            id: cat.id,
                            text: cat.name.toUpperCase()
                        }))}
                                      defaultValue={props.currentCategory}
                                      defaultText={t("overall", props.lang)}
                                      callback={(id) => props.filterCategory(id)}/>

                        {!inIframe() && !props.replay &&
                            <>
                                <div className="expert-mode">
                                    {props.expert &&
                                        <Toggle checked={props.bestSectorMode} label="best_sectors" onChange={toggleBestSectorMode}
                                                lang={props.lang}/>
                                    }
                                    <Toggle checked={props.expert} label="expert_mode" onChange={toggleExpert} lang={props.lang} />
                                </div>
                            </>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}
const LiveTableFilter = connect(storeToProps, storeDispatchToProps)(LiveTableFilterView);
export default LiveTableFilter;