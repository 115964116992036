import React, {Component} from 'react';
import {connect} from "react-redux";
import {FlagsEnum} from "../../../../../../../../../server/common/Enum/live/FlagsEnum";
import LiveReducerType from "../../../type/LiveReducerType";
import {t} from "../../../translation/translator";
import ReactTooltip from 'react-tooltip';
import ReducerType from "../../../type/ReducerType";
import FlagProgress from "../../../../../../../../../server/common/Models/live/FlagProgress";

const storeToProps = (state: ReducerType) => ({
    progressFlagState: state.live.live.progressFlagState,
    currentState: state.live.live.params.raceState,
    elapsedTime: state.live.live.params.elapsedTime,
    totalTime: state.live.live.params.duration,
    lang: state.live.lang
});
type Props = ReturnType<typeof storeToProps>;

const PlayerTimeProgressView = (props: Props) => {
    // on met à jour la durée du drapeau courant :
    let current = props.progressFlagState.find(p => p.current);
    if (current) {
        let totalProgress = 0;
        props.progressFlagState.forEach((value,index) => {
            if (!value.current) {
                totalProgress += value.percentProgress;
            }
        });
        current.percentProgress = 100 * (props.elapsedTime / props.totalTime);
        current.percentProgress -= totalProgress;
    }
    //flags.push(current);
    return (
        <div className="timeline-progress">
            <div className="timeline-progress-inner">
                {props.progressFlagState.map((value, index) => {
                    return <Unit state={value.state} lang={props.lang} key={index}
                                 width={value.percentProgress}/>
                })}
                {/*<Unit state={props.currentState} lang={props.lang}*/}
                {/*      width={current.percentProgress}/>*/}
            </div>
            <ReactTooltip/>
        </div>
    );
}

const Unit = (props: { state: string, width: number, lang: string }) => {
    let flagStateToColor = {
        [FlagsEnum.GREEN]: "green",
        [FlagsEnum.RED]: "red",
        [FlagsEnum.SAFETY]: "white",
        [FlagsEnum.VIRTUAL_SAFETY]: "white",
        [FlagsEnum.YELLOW]: "yellow",
        [FlagsEnum.FULL_YELLOW]: "yellow",
        [FlagsEnum.CHECK]: "gray"
    };
    let flagStateToText = {
        [FlagsEnum.GREEN]: t("green_flag", props.lang),
        [FlagsEnum.RED]: t("red_flag", props.lang),
        [FlagsEnum.SAFETY]: t("safety_car_flag", props.lang),
        [FlagsEnum.VIRTUAL_SAFETY]: t("virtual_safety_car_flag", props.lang),
        [FlagsEnum.YELLOW]: t("yellow_flag", props.lang),
        [FlagsEnum.FULL_YELLOW]: t("full_yellow_flag", props.lang)
    };
    let classes = (FlagsEnum.CHECK == props.state) ? `unit ${props.state}` : `unit ${flagStateToColor[props.state]}`;
    let width = props.width ? props.width + "%" : "0%";
    return (
        <>
            <div className={classes} style={{width: width}} title={flagStateToText[props.state]}></div>
        </>
    )
};


const PlayerTimeProgress = connect(storeToProps, null)(PlayerTimeProgressView);
export default PlayerTimeProgress;